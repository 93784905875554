.complex-interrogations-wrapper {
    display: table-row;
    height: 100%;
    background-color: #ECF1F3;
    white-space: pre-line;
}

.complex-interrogations {
    padding: 0px 10px 10px 10px;
    margin-bottom: 15px;
    background: white;
    -webkit-box-shadow: 1px 1px 6px -2px #888;
    box-shadow: 1px 1px 6px -2px #888;
}

.complex-interrogations h4 {
    margin-left: 7px;
    text-decoration: underline;
}

.complex-interrogations .row {
    padding: 0 15px 15px 15px;
}

.container-fluid,
.row {
    margin: 0;
    border: 0;
    padding: 0;
}

div.details-sidebar {
    padding: 0;
    background: #fff;
    z-index: 2;
}

@media screen and (min-width: 768px) {
    div.details-sidebar {
        display: table-cell;
        vertical-align: top;
        float: none;
        -webkit-box-shadow: 1px 0 6px -2px #888;
        box-shadow: 1px 0 6px -2px #888;
        min-width: 238px;
    }
}

div#sidebar-details-header {
    color: #fff;
    position: relative;
    padding: 8px 15px;
    background: #89a29e;
    -webkit-box-shadow: 0 1px 6px -2px #888;
    box-shadow: 0 1px 6px -2px #888;
}

div#sidebar-details-title {
    font-size: 1.1em;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div#sidebar-details-header-arrow {
    position: absolute;
    top: 0;
    right: -10px;
    height: 0;
    width: 0;
    border-left: 10px solid #89a29e;
    border-right: 0 solid transparent;
    border-bottom: 19px solid transparent;
    border-top: 19px solid transparent;
    z-index: 1;
}

@media screen and (min-width: 768px) {
    div.details-content {
        vertical-align: top;
        display: table-cell;
        float: none;
    }
}

div.details-content {
    padding: 0;
    background-color: white;
}

@media (min-width: 1200px) {
    .col-lg-10 {
        width: 83.33333333%;
    }
}

div#sidebar-details-title a, div#sidebar-details-title a:active, div#sidebar-details-title a:focus, div#sidebar-details-title a:hover {
    color: #fff;
    text-decoration: none;
}

div.sidebar-details-account-category {
    padding: 8px 15px;
    border-bottom: 1px solid #edf1f2;
}

div.sidebar-details-account-category>a {
    color: #111;
    font-weight: 700;
}