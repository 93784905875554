div.crumb {
	padding: 7px 0;
	font-size: 1.1em;
	background: rgba(24, 58, 55, 0.85);
	background: -webkit-linear-gradient(left, rgba(24, 58, 55, 0.85) , rgba(14, 123, 62, 0.85)); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(right, rgba(24, 58, 55, 0.85), rgba(14, 123, 62, 0.85)); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(right, rgba(24, 58, 55, 0.85), rgba(14, 123, 62, 0.85)); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to right, rgba(24, 58, 55, 0.85) , rgba(14, 123, 62, 0.85)); /* Standard syntax */
}

.crumb a {
	color: white;
	text-decoration: none;
}

.crumb a:hover {
	color: #8AA29E;
	text-decoration: none;
}

.crumb a:active {
	text-decoration: none;
}

ul.crumb-list {
	height: 100%;
	margin: 0;
	background: none;
}

.crumb-home {
	display: inline-block;
	height: 15px;
	width: 15px;
	background: url('home.svg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.crumb-search {
	padding: 0 15px;
}
