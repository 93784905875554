.imageDropdown {
  position: relative;
  display: inline-block;
  width: 40px;
  font-family: Arial, sans-serif;
  /* border: 1px solid #ccc;
  background-color: #fff; */
  cursor: pointer;
}

/* Dropdown header (closed state) */
.imageDropdown-header {
  display: flex;
  justify-content: center;
  padding: 4px;
  align-items: center;
  border: 2px solid #294C35;
  background-color: #305C53;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  /* color: #fff; */
}

.imageDropdown-header:hover {
  background-color: #2a584f;
}

.imageDropdown img {
  width: 30px;
  height: 30px;
}

.imageDropdown-header img:hover {
  transform: scale(1.3);
}

/* Dropdown options list */
.imageDropdown-options {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  /* overflow-y: auto; */
  border: 1px solid #08493c;
  background-color: #305C53;
  border-radius: 0 0 4px 4px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  display: none;
}

/* Dropdown options list (visible state) */
.imageDropdown.visible .imageDropdown-options {
  display: block;
}

/* Dropdown option items */
.imageDropdown-options li {
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Dropdown option items (hover state) */
.imageDropdown-options li:hover {
  background-color: #6fb985;
}

/* Selected option */
.imageDropdown-options li.selected {
  background-color: #2e8568;
  color: #fff;
}

/* Selected option text */
.imageDropdown-options li.selected:hover {
  background-color: #14683e;
}