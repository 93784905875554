@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700');

html {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif !important;
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('loading.gif') rgba(24, 118, 64, .75) no-repeat center center;
  background-size: 40px 40px;
  z-index: 1000;
}

div.missing {
  text-align: center;
  padding: 15px 15px 0 15px;
  color: #B0AEB0;
}

div#root {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  padding: 0;
}

div#root button {
  border-radius: 2px;
}

.container-fluid {
  margin: 0;
  border: 0;
  padding: 0;
}

.row {
  margin: 0;
  border: 0;
  padding: 0;
}

div.floater {
  position: fixed;
  width: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
}

@media screen and (max-width: 767px) {
  div.floater {
    position: relative;
  }
}

div.fixer {
  color: rgba(0, 0, 0, 0);
}

div.notification-circle {
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  background: #EE0000;
  border-radius: 15px;
  line-height: 16px;
}

div.count-circle {
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  background: #8AA29E;
  border-radius: 15px;
}

input:focus {
  outline: 0 !important;
}

select:focus {
  outline: 0 !important;
}

button:focus {
  color: white;
  outline: 0 !important;
}

* {
  max-width: 100vw;
}
