div.account-state {
	padding-top: 30px;
	background: white;
}

div.account-state-inner a {
	text-decoration: none;
}

div.account-state-inner a:hover {
	text-decoration: none;
}

div.account-state-inner {
	padding-bottom: 45px;
	border-bottom: 1px solid #E8F1F2;
}
