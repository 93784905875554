.floating-label-input {
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .floating-label-input input {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 6px;
    outline: none;
  }
  
  .floating-label-input label {
    position: absolute;
    left: 12px;
    top: 10px;
    transition: 0.2s ease all;
  }
  
  .floating-label-input.focused label {
    top: -12px;
    font-size: 12px;
    color: #007bff; /* Color when focused */
  }
