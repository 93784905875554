.topConfirmModal{
  /* display: flex; */
  width: 500px;
  height: 514px;
}

.confirmModal {
  top: 30vh;
  background-color: rgb(218, 184, 139);
}

.confirmModal .close {
  width: 25px;
  height: 25px;
  font-size: 25px;
  font-weight: bolder;
}

.confirmModal .closeBtn{
  background-color: rgb(189, 146, 87);
}

.confirmModal p {
  margin-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}

.confirmButtons {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 10px;
}

.confirmButtons .noConfirm {
  background-color: rgb(202, 36, 36);
}

.confirmButtons .noConfirm:hover {
  background-color: rgb(114, 18, 18);
}