input.search-text {
    border: none;
    color: #111;
    border-radius: 0;
    box-shadow: none;
}

div.search-inputs {
    background: #fff;
    padding: 0;
    margin-bottom: 5px;
}

div.search-inputs .input-group-sm > .form-control {
    font-size: 13px;
}

.search-suggestions {
    display: inline-block;
    background-color: #fdfdfd;
    height: 100%;
    width: 100%;
    z-index: 1001;
}

.search-suggestions-content {
    background-color: #fdfdfd;
    position: absolute;
    top: 34px; /* fixes an extra bit of white space, a bit hacky tho */
    width: 100%;
    z-index: 1001;
}

.search-suggestion {
    color: #000;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 5px 10px;
    border-top: 1px solid #ddd;
    z-index: 1001;
}

.search-suggestion .left {
    text-align: left;
}

.search-suggestion .right {
    text-align: right;
}

.search-suggestion.selected {
    background-color: rgba(14, 123, 61, 0.3);
    color: #fff;
}

.search-suggestion.selected .search-suggestion-cui {
    color: #f2f2f2;
}

.search-suggestion:last-child {
    border-bottom: 1px solid #ddd;
}

.search-suggestion em {
    color: #999;
}

.search-suggestion-cui {
    color: #bfbfbf;
    margin-left: 5px;
}

#search-loading-dimmer {
    top: 34px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.searchButton {
    margin-right: 1px;
}
