.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination button {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #333;
  padding: 8px 12px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #f2f2f2;
}

.pagination button:disabled {
  color: #999;
  cursor: not-allowed;
}

.pagination .active button {
  background-color: #4caf50;
  color: #fff;
}