div.new {
	height: auto;
	background: white;
	padding: 80px 0 100px;
}

div.new-head {
	padding: 20px 0 40px;
	text-align: center;
}

div.new-head h2 {
	margin: 0;
	border: 0;
	padding: 0;
}

div.new-box {
	position: relative;
	display: table;
	border-collapse: collapse;
	height: 100%;
	margin: 15px 0;
}

div.new-box-content {
	display: table-row;
	height: 100%;
}

div.left-content {
	display: table-cell;
	width: 40%;
	border-right: 5px solid #0E7B3E;
}

div.new-companies {
	background-image: url('new-companies.svg');
	background-position: 90% 50%;
	background-size: auto 55%;
	background-repeat: no-repeat;
}

div.new-dossiers {
	background-image: url('new-dossiers.svg');
	background-position: 90% 50%;
	background-size: auto 48%;
	background-repeat: no-repeat;
}

div.insolvency {
	background-image: url('insolvency.svg');
	background-position: 90% 50%;
	background-size: auto 45%;
	background-repeat: no-repeat;
}

div.right-content {
	display: table-cell;
	width: 60%;
	height: 100%;
	padding: 0 0 0 15px;
}

div.right-count {
	color: #0E7B3E;
	font-size: 60px;
}

div.right-description {
	font-size: 20px;
}

@media all and (max-width:1200px) {
	div.right-count {
		font-size: 40px;
	}

	div.right-description {
		font-size: 14px;
	}
}