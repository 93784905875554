body.modal-open {
	height: 100vh;
	overflow: hidden;
}

/* overriding bootstrap */
.modal-open .modal {
	overflow-y: hidden;
}

div.modals {
	display: block;
	background: rgba(24, 58, 55, 0.85);
}

.modals-modal {
	border-radius: 10px;
	overflow: auto;
	max-height: 94vh;
}

div.modals-row {
	margin-bottom: 15px;
}

button.modals-close-button {
	background-color: #EEF0F2; 
	font-size: 3em;
	font-weight: 100;
	width: 40px;
	height: 40px;
}

h3.modals-title {
	text-align: center;
	margin: 10px 0 30px 0;
}

p.modals-message {
	color: red;
	text-align: center;
	margin-bottom: 15px;
}

p.modals-message-green {
	color: green;
	text-align: center;
	margin-bottom: 15px;
}

input.modals-input,
select.modals-select {
	border-radius: 0;
	margin-bottom: 15px;
}

input.cod-recom,
select.cod-recom {
	display: inline;
}

input.modals-check {
	margin: 0;
	cursor: pointer;
}

a.modals-link {
	cursor: pointer;
}

button.modals-btn {
	margin-bottom: 15px;
}

div.modals-or {
	height: 15px;
	margin: 15px 0;
	border-top: 1px solid #ADADAD;
}
div.modals-or-center {
	margin-top: -12px;
	text-align: center;
}

span.modals-or {
	background: white;
	padding: 0px 5px;
	color: #ADADAD;
}

.bg-facebook {
	background-color: #3A579B;
}

div.modals-row-bottom {
	text-align: center;
	margin-bottom: 30px;
}