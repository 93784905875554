.simpleButton {
    display: inline-block;
    padding: 8px 10px;
    background-color: #69ac6c;
    color: #fff;
    cursor: pointer;
    border: #69ac6c;
    border-radius: 4px !important;
    text-align: center;
  }

  .simpleButton:hover {
    background-color: #025a1d;
  }