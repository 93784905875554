.videoClose {
    color: crimson;
    background: linear-gradient(to right, #035D56, #3AABA2 );
}

.topVideoModal {
    display: flex;
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
}

.videoModal {
    background: linear-gradient(to right, #035D56, #3AABA2 );
    width: 100%;
}

.htmlVideo{
    width: 400%;
    height: 94vh;
}
