div.navigation-buttons button,
.navigation-buttons.btn-toolbar > .btn {
  font-size: 1em;
  margin: 0 0 0 5px;
}

div.navigation-notification {
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  float: right;
  height: 15px;
  margin-top: 3px;
  margin-left: 5px;
}

@media screen and (max-width: 1280px) {
  div.navigation-buttons button,
  .navigation-buttons.btn-toolbar > .btn {
    margin: 0;
  }

  .navigation-buttons.btn-toolbar > .btn.bg-cadet-grey {
    margin-right: 5px;
  }
}

@media screen and (max-width: 1025px) {
  div.navigation-buttons button,
  .navigation-buttons.btn-toolbar > .btn {
    margin: 0;
  }

  div.title-logo {
    width: 30px;
  }
}

@media screen and (max-width: 1366px) {
  .logo-link {
    display: block;
    width: 40px;
    overflow: hidden;
  }
}

@media screen and (max-width: 992px) {
  div.title-logo {
    width: 180px;
  }
  .logo-link {
    overflow: visible;
  }

  @keyframes navigationButtonsHide {
    from {
      margin-left: 0;
    }
    to {
      margin-left: -66%;
    }
  }

  div.navigation-buttons {
    height: 100%;
    width: 66%;
    opacity: 1 !important;
    margin-left: -66%;
    padding-top: 61px;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(24, 58, 55, 1);
    background: -webkit-linear-gradient(left, rgba(24, 58, 55, 1), rgba(14, 123, 62, 1));
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, rgba(24, 58, 55, 1), rgba(14, 123, 62, 1));
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, rgba(24, 58, 55, 1), rgba(14, 123, 62, 1));
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, rgba(24, 58, 55, 1), rgba(14, 123, 62, 1));
    /* Standard syntax */
    animation-name: navigationButtonsHide;
    animation-duration: 0.3s;
  }

  div.navigation-buttons button {
    margin: 0 0 5px 0 !important;
    width: 100%;
  }

  @keyframes navigationButtonsShow {
    from {
      margin-left: -66%;
    }
    to {
      margin-left: 0;
    }
  }

  div.navigation-buttons.open {
    margin-left: 0;
    animation-name: navigationButtonsShow;
    animation-duration: 0.3s;
  }
}
