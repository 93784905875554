.title {
    width: 800px;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: -12px;
}

img.title-logo {
    height: 30px;
    width: 180px;
    background-size: auto 100%;
    background-position: left;
    background-repeat: no-repeat;
    /* margin-right: 2px; */
    /* display: inline-block;
    position: absolute;
    z-index: 999; */
}

@media screen and (max-width: 768px) {
    .title {
        display: flex;
        height: 60px;
    }

    img.title-logo {
        display: none;
    }
}

.icap-img {
    height: 68px;
    width: 524px;
}

h1.title-text a {
    color: white;
    text-decoration: none;
}

h1.title-text a:hover {
    text-decoration: none;
}

h1.title-text a:active {
    text-decoration: none;
}