.dropdown {
    position: relative;
    display: inline-block;
    width: 200px;
    font-family: Arial, sans-serif;
    cursor: pointer;
  }
  
  /* Dropdown header (closed state) */
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: #409b57;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }

  .dropdown-header:hover {
    background-color: #227a30;
  }

  .dropdown-header img {
    width: 15px;
    height: 15px;
    margin-top: 2px;
  }

  .dropdown-header img:hover {
    transform: scale(1.3);
  }

  /* Dropdown options list */
  .dropdown-options {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    text-align: center;
    display: none;
  }
  
  /* Dropdown options list (visible state) */
  .dropdown.visible .dropdown-options {
    display: block;
  }
  
  /* Dropdown option items */
  .dropdown-options li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  /* Dropdown option items (hover state) */
  .dropdown-options li:hover {
    background-color: #6fb985;
  }
  
  /* Selected option */
  .dropdown-options li.selected {
    background-color: #2e8568;
    color: #fff;
  }
  
  /* Selected option text */
  .dropdown-options li.selected:hover {
    background-color: #14683e;
  }