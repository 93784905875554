div.about {
  position: relative;
  background: rgba(141, 189, 163, 0.9);
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  div.about {
    background: rgba(141, 189, 163, 1);
  }
}

div.square {
  color: #111;
  position: relative;
  height: 0;
  width: 100%;
  padding: 0 0 100% 0;
}

div.square-content {
  position: absolute;
  display: block;
  left: 20px;
  top: 40px;
  width: calc(100% - 40px);
  height: calc(100% - 60px);
  background: #fff;
  transition: background-color 0.4s ease;
}

div.square:hover div.square-content {
  color: #fff;
  background-color: #0E7B3E;
}

div.square-header {
  margin-top: 15px;
  height: 30%;
}

div.square-body {
  height: 40%;
  overflow: hidden;
}

div.square-body > h3 {
  margin: 0;
  text-align: center;
  font-size: 24px;
}

div.square-body > p {
  padding: 10px 25px;
  color: #B8C1C2;
  text-align: center;
  font-size: 15px;
  transition: color 0.4s ease;
}

div.square:hover div.square-body > p {
  color: #fff;
}

div.square-footer {
  height: 20%;
  text-align: center;
}

span.btn-square {
  color: #8AA29E;
  display: inline-block;
  padding: 5px 0;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 2px solid #E8F1F2;
}

div.square:hover span.btn-square {
  color: #fff;
  border-bottom: 2px solid #fff;
}

div.easy-monitoring-header {
  background-image: url('easy-monitoring.svg');
  background-position: center;
  background-size: auto 40%;
  background-repeat: no-repeat;
}

div.square:hover div.easy-monitoring-header {
  background-image: url('easy-monitoring-white.svg');
}

div.data-chart-header {
  background-image: url('data-chart.svg');
  background-position: center;
  background-size: auto 45%;
  background-repeat: no-repeat;
}

div.square:hover div.data-chart-header {
  background-image: url('data-chart-white.svg');
}

div.actuality-header {
  background-image: url('actuality.svg');
  background-position: center;
  background-size: auto 50%;
  background-repeat: no-repeat;
}

div.square:hover div.actuality-header {
  background-image: url('actuality-white.svg');
}

div.about a:hover {
  text-decoration: none
}

@media screen and (max-width: 768px) {
  div.square {
    padding-bottom: 300px;
  }

  div.square-content {
    left: 0;
    top: 20px;
    width: 100%;
    height: calc(100% - 20px);
  }

  div.list-content-header .input-group {
    right: 10px !important;
  }
}
