div.controls {
	padding-bottom: 15px;
	background: rgba(138, 162, 158, 0.85);
}

@media screen and (max-width: 768px) {
	div.controls {
		display: none;
	}
}

.flexDisplay {
	display: flex;
}

div.control {
	margin-top: 15px;
	margin-left:0px;
}

div.control-inner {
	position: relative;
	height: 0;
	background: #fff;
	padding-bottom: 66.66%;
	text-align: center;
	transition: background-color 0.4s ease;
}

div.control-inner h4 {
	color: #111;
}

div.control-inner p {
	color: #8AA29E;
}

div.control-inner div.monitoring-graphic {
	background: url('monitoring.svg');
	background-position: center;
	background-size: auto 40%;
	background-repeat: no-repeat;
}

div.control-inner div.company-control-graphic {
	background: url('company.svg');
	background-position: center;
	background-size: auto 50%;
	background-repeat: no-repeat;
}

div.control-inner div.associate-control-graphic {
	background: url('associate.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-inner div.messaging-control-graphic {
	background: url('messaging.svg');
	background-position: center;
	background-size: auto 40%;
	background-repeat: no-repeat;
}

div.control-inner div.marketing-control-graphic {
	background: url('marketing.svg');
	background-position: center;
	background-size: auto 50%;
	background-repeat: no-repeat;
}

div.control-inner div.settings-control-graphic {
	background: url('settings.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-inner div.tutorial-control-graphic {
	background: url('help.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-inner div.istoric-control-graphic {
	background: url('company.svg');
	background-position: center;
	background-size: auto 50%;
	background-repeat: no-repeat;
}

div.control-inner div.dossier-control-graphic {
	background: url('dossier.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-inner div.interrogation-control-graphic {
	background: url('search.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-inner:hover {
	background: #0E7B3E;
	cursor: pointer;
}

div.control-inner:hover h4 {
	color: white;
}

div.control-inner:hover p {
	color: white;
}

div.control-inner:hover div.monitoring-graphic {
	background: url('monitoring-hover.svg');
	background-position: center;
	background-size: auto 40%;
	background-repeat: no-repeat;
}

div.control-inner:hover div.company-control-graphic {
	background: url('company-hover.svg');
	background-position: center;
	background-size: auto 50%;
	background-repeat: no-repeat;
}

div.control-inner:hover div.associate-control-graphic {
	background: url('associate-hover.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-inner:hover div.messaging-control-graphic {
	background: url('messaging-hover.svg');
	background-position: center;
	background-size: auto 40%;
	background-repeat: no-repeat;
}

div.control-inner:hover div.marketing-control-graphic {
	background: url('marketing-hover.svg');
	background-position: center;
	background-size: auto 50%;
	background-repeat: no-repeat;
}

div.control-inner:hover div.settings-control-graphic {
	background: url('settings-hover.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-inner:hover div.tutorial-control-graphic {
	background: url('help-hover.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-inner:hover div.istoric-control-graphic {
	background: url('company-hover.svg');
	background-position: center;
	background-size: auto 50%;
	background-repeat: no-repeat;
}

div.control-inner:hover div.interrogation-control-graphic {
	background: url('search-hover.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-inner:hover div.dossier-control-graphic {
	background: url('dossier-hover.svg');
	background-position: center;
	background-size: auto 60%;
	background-repeat: no-repeat;
}

div.control-content {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

div.control-graphic {
	height: 50%;
	width: 100%;
	margin-top: 5px;
	position: relative;
}

div.control-text {
	height: 50%;
	width: 100%;
}

div.control-text h4 {
	margin: 0 0 5px 0;
}

div.controls-notification {
	width: auto;
	height: 20px;
	position: absolute;
	top: 20%;
	right: 35%;
}

@media screen and (max-width: 768px) {
	div.control-inner {
		padding-bottom: 160px;
	}
}
