/*
If someone decides to roll their own custom checkbox
border-color: #107a3f;
background: #107a3f;
*/

.licitatii {
    display: inline-flex;
    justify-content: space-between; 
}

.licitatii .item {
    max-width: 150px;
    margin: 3px 0px 0px 30px;
}

.administrativ {
    display: inline-flex;
    justify-content: space-between; 
}

.administrativ .item {
    width: 50px;
    margin-left: 5px;
}

.administrativ label {
    margin-left: 5px;
    color: black;
}