.demoDetails {
    padding: 50px 15px;
    color: #ffffff;
}

div.new-head {
    padding: 0 !important;
}

.demoDetails h3 {
    color: #ffffff;
    font-weight: bold;
}

.demoDetails p {
    margin: 20px 0;
}

.demoDetails ul {
    padding-left: 60px;
}

.demoDetails li {
    margin: 10px 0;
    list-style: none;
}

.demoDetails li:before {
    color: #ffffff;  
    content: "\2713"; 
    font-size: 1em; 
    padding-right: 1.1225em; 
    position: relative;
    top: 0em; 
}

.demoDetails .new-footer {
    margin-top: 80px;
}

.demoDetails h4 {
    text-align: center;
    font-weight: bold;
}

.demoDetails .square{
    height: auto;
    padding: 0 0 50% 0;
}

.demoDetails .square-content {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 9px 0 rgba(0, 0, 0, 0.1);
    height: 200px;
}

.demoDetails .square-content .square-footer{
    height: auto;
}

.demoDetails .square-content .square-body{
    height: 70%;
}

.demoDetails .square-body p{
    color: #8aa29e;
}

.demo-table {
    margin: 10px 0 30px 20px;
}

.demo-table td {
    padding: 15px;
    border-bottom: 1px solid #e6e6e6;
}

.demo-table td:first-child {
    border-right: 1px solid #e6e6e6;
}

.demo-table tr:first-child td:first-child {
    border-right: 1px transparent;
}

.demo-center {
    text-align: center;
}

.demoDetails a {
    cursor: pointer;
    display: inline-block;
    padding: 5px 0;
    font-weight: bold;
    color: #ffffff;
    border-bottom: 2px solid #ffffff;
}

.demoDetails a:hover {
    color: #f2f2f2;
    border-bottom-color: #E8F1F2;
}

.demoDetails .info-tooltip-trigger {
    color: #f2f2f2;
}

@media screen and (max-width: 768px) {
    .demo-table {
        margin-left: 0;
    }
    .demo-table td {
        padding: 5px;
        font-size: 12px;
    }
    .demoDetails ul {
        padding-left: 20px;
    }
}