.sendEmailMContainer {
    z-index: 0;
}

.sendEmailModal {
    top: 30vh;
    background-color: rgb(218, 184, 139);
}

.sendEmailModal .close {
    width: 25px;
    height: 25px;
    font-size: 25px;
    font-weight: bolder;
}

.sendEmailModal .closeBtn {
    background-color: rgb(189, 146, 87);
}

.sendEmailModal .newEmailInput {
    padding-left: 100px;
    padding-right: 100px;
}