.api-modal {
  width: 60%;  
}

.apiModalSearch {
  display: flex;
  align-items: center;
}

.apiSearchButton {
  margin-left: 10px;
}