div.app {
	position: relative;
	height: 100%;
	z-index: 0;
}

div.app-bg-image {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: url('./Icons/aboutBackground.jpg') top right fixed no-repeat;
	background-size: 120%;
}

button.btn-lnk-cadet-grey {
	color: #8AA29E;
	font-weight: bold;
	text-decoration: none;
}

button.btn-lnk-cadet-grey:hover,
button.btn-lnk-cadet-grey:focus {
	color: #0E7B3E !important;
	text-decoration: none;
}

button.btn-lnk-cadet-grey:active {
	text-decoration: none;
}

.btn {
	color: #fff;
	border: none;
	border-radius: 3px;
}

.btn.disabled {
	pointer-events: auto !important; /* Overrides a default setting in button.less */
	cursor: progress;
}

.btn:hover,
.btn:active,
.btn:focus {
	color: #EDF1F2;
}

.bg-msu-green {
	background-color: #183A37;
}

.bg-la-salle-green {
	background-color: #0E7B3E;
}

.positionRight{
	float:right;
}

.bg-cadet-grey {
	background-color: #8AA29E;
}

.bg-green-grey {
	background-color: #89A29E;
}

.bg-orange {
	background-color: #F79256;
}

.btn-block {
	overflow: hidden;
	text-overflow: ellipsis;
}

.hidden {
	visibility: hidden;
}

.margin-top-10 {
	margin-top: 10px !important;
}

.margin-top-15 {
	margin-top: 15px !important;
}

.margin-bottom-5 {
	margin-bottom: 5px !important;
}

.margin-bottom-10 {
	margin-bottom: 10px !important;
}

.margin-bottom-15 {
	margin-bottom: 15px !important;
}

.margin-bottom-25 {
	margin-bottom: 25px !important;
}

.margin-top-0 {
	margin-top: 0 !important;
}

.margin-bottom-0 {
	margin-bottom: 0 !important;
}

.padding-left-0 {
	padding-left: 0 !important;
}

.padding-right-0 {
	padding-right: 0 !important;
}
.padding-bottom-10 {
	padding-bottom: 10px !important;
}

.padding-bottom-5 {
	padding-bottom: 5px !important;
}

.padding-top-10 {
	padding-top: 10px !important;
}

.padding-top-0 {
	padding-top: 0 !important;
}

.position-relative {
	position: relative;
}

.toast-notification {
	width: 100% !important;
	right: 10px !important;
	top: 72px !important;
	left: auto !important;
	text-align: right !important;
}

.bordered-content-bottom {
	border-bottom: 1px solid #E8F1F2;
	overflow: hidden;
	padding-bottom: 5px;
	margin-bottom: 15px;
}
.bordered-content-inner {
	margin-left: -30px;
}

.text-right {
	text-align: right !important;
}

.text-left {
	text-align: left !important;
}

@media screen and (max-width: 991px) {
	.table-responsive {
		width: calc(100vw - 320px);
	}
}

@media screen and (max-width: 767px) {
	.table-responsive {
		width: calc(100vw - 80px);
	}
}

th, td {
	text-align: left;
	padding: 10px;
	word-wrap: break-word;
}

th {
	background-color: #04AA6D;
	color: white
}

table.interogations-advanced-table{
	width: 100%;
	border-bottom: 1px solid gray;
	margin-bottom: 2rem;
}

.header tr {
	size: 10px
}

thead, tbody tr {
	display: table;
	width: 100%;
	table-layout: fixed;
}

table, th, td {
	border-top: 1px solid gray;
	border-left: 1px solid gray;
	border-right: 1px solid gray;
	border-bottom: 1px solid gray;
}