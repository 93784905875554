.raportImage {
    margin-left: 10%;
    overflow: scroll;
    height: 970px;
    width: 1580px;
}

.raportImageGRM {
    margin-left: 10%;
    overflow: scroll;
    height: 751px;
    width: 1573px;
}

.raportImage button {
    margin-bottom: -40px;
    color: red;
    background-color: black;
    padding-top: -10px;
}

.raportImageGRM button {
    margin-bottom: -40px;
    color: red;
    background-color: black;
    padding-top: -10px;  
}
