.historyTable .tbody {
    display: block;
    max-height: 530px;
    overflow: auto;
}

.scrollable {
    width: calc( 100% - 1.2em )
}

.size-row {
    font-size: 18px;
}


