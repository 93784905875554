div.header {
	color: white;
	padding: 15px;
	border-bottom: 1px solid rgba(138, 162, 158, 0.85);
	background: rgba(24, 58, 55, 0.85);
	background: -webkit-linear-gradient(left, rgba(24, 58, 55, 0.85) , rgba(14, 123, 62, 0.85)); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(right, rgba(24, 58, 55, 0.85), rgba(14, 123, 62, 0.85)); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(right, rgba(24, 58, 55, 0.85), rgba(14, 123, 62, 0.85)); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to right, rgba(24, 58, 55, 0.85) , rgba(14, 123, 62, 0.85)); /* Standard syntax */
	display: flex;
	/* justify-content: space-between; */
}

@media screen and (min-width: 768px) {
	.col-sm-auto { width: auto; }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    div.header {
        padding-right: 15px;
     }
 }
