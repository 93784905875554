div.footer {
	background: #2A2B2F;
	padding: 20px 0 25px;
	max-height: 100px;
	overflow: hidden;
	position: relative;
	z-index: 3;
}

@media screen and (max-width: 992px) {
	div.footer {
      max-height: 10000px;
	}
}

div.footer h6 {
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 20px;
}

div.footer p,
div.footer p a {
	color: #aaa;
	font-size: 13px;
}