div.recently-viewed {
	position: relative;
	background: white;
	padding-bottom: 30px;
}

div.recently-viewed-head {
	text-align: center;
	margin-bottom: 15px;
}

div.recently-viewed-card {
	display: table;
	height: 100%;
}

p.recently-viewed-message {
	text-align: center;
}