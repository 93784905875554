.checkbox-label {
    display: flex;
    align-items: center;
}

.checkbox-label span {
    margin-left: 10px;
    font-size: 19px;
    margin-top: 5px;
}

.custom-checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #007bff;
    /* Border color when unchecked */
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.2s ease all;
}

.custom-checkbox:checked {
    background-color: #007bff;
    /* Background color when checked */
    border-color: #007bff;
    /* Border color when checked */
}

.custom-checkbox:checked::before {
    content: "\2713";
    /* Unicode checkmark character */
    display: block;
    text-align: center;
    font-size: 12px;
    color: white;
}

.custom-checkbox:focus {
    outline: none;
}