.header > .navigation {
	width: 100%;
}

@media screen and (max-width: 992px) {

	@keyframes navigationHide {
		0% {
			background-color: rgba(0, 0, 0, 0.85);
			visibility: visible;
		}
		99% {
			background-color: rgba(0, 0, 0, 0.0);
			visibility: visible;
		}
		100% {
			background-color: rgba(0, 0, 0, 0);
			visibility: hidden;
		}
	}

	div.navigation {
		position: fixed;
		top: 0;
		left: 0;
		padding: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba(0, 0, 0, 0);
		z-index: 1000;
		animation-name: navigationHide;
		animation-duration: 0.3s;
		visibility: hidden;
	}

	@keyframes navigationShow {
		from {
			background-color: rgba(0, 0, 0, 0);
			visibility: visible;
		}
		to {
			background-color: rgba(0, 0, 0, 0.85);
			visibility: visible;
		}
	}

	div.navigation.open {
		background-color: rgba(0, 0, 0, 0.85);
		visibility: visible;
		animation-name: navigationShow;
		animation-duration: 0.3s;
	}
}

button.btn-no-bg {
	background: none;
}
