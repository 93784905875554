div.body {
	position: relative;
	width: 100%;
}

div.util-title {
	border-bottom: 1px solid #F2F4F4;
	padding: 45px 0 30px 0;
	margin-bottom: 30px;
}

.util-title h2 {
	text-align: center;
	margin: 0;
}

.util-content {
	margin-bottom: 30px;
}