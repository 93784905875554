.topInsGroupModal{
  /* display: flex; */
  width: 500px;
  height: 514px;
}

.insGroupModalUpdate {
  top: 20vh;
  background-color: rgb(121, 151, 109);
}

.insGroupModalAdd {
  top: 5vh;
  background-color: rgb(121, 151, 109);
}

.insGroupModal .close {
  width: 25px;
  height: 25px;
  font-size: 25px;
  font-weight: bolder;
}

.insGroupModal .closeBtn{
  background-color: rgb(7, 29, 5);
}

.insActions {
  margin: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.insAddGroup {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}