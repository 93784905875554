div#search {
    color: white;
    background: rgba(24, 58, 55, 0.85);
    background: -webkit-linear-gradient(left, rgba(24, 58, 55, 0.85), rgba(14, 123, 62, 0.85));
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, rgba(24, 58, 55, 0.85), rgba(14, 123, 62, 0.85));
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, rgba(24, 58, 55, 0.85), rgba(14, 123, 62, 0.85));
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, rgba(24, 58, 55, 0.85), rgba(14, 123, 62, 0.85));
    /* Standard syntax */
    padding: 0px 15px 50px;
    /* padding-top: 50px; */
}

div.search-spacer {
    width: 100%;
    height: 30px;
}

h2.home-subheading {
    margin: 0 0 10px;
    font-size: 14px;
}
