.closeBtn {
    background-color: #A3A8A5;
}

.topFirstLogin{
    display: flex;
    width: 800px;
    height: 514px;
}

.firstLogin {
	border-radius: 10px; 
    top: 130px;
}

.imageDiv {
    display: flex;
    justify-content: center;
    background-color: #A3A8A5;
    padding-bottom: 20px;
}

.firstLogin .image {
    filter: invert(17%) sepia(8%) saturate(6177%) hue-rotate(99deg) brightness(59%) contrast(72%);
    width: 400px;
    height: 100px;
}

.firstLogin .header {
    text-align: center;
    font-weight: bold;
    text-shadow: 2px 2px #838784;
    font-size: 40px;
    margin-top: 20px;
}

.firstLogin .body {
    font: 18px Arial sans-serif;
    text-align: center;
    padding: 0px 20px 0px 20px;
    color: #545856;
}

.firstLogin .footerBtn {
    text-align: center;
    padding-top: 40px;
    margin-top: 30px;
    height: 100px;
    background-color: #A3A8A5;
}
