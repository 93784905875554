.details-firms h4 {
    text-align: left;
}

.details-firms .main-list {
    padding-left: 10px;
    margin-bottom: 30px;
}

.details-firms .main-list .expandable {
    cursor: pointer;
}

.details-firms .main-list li.expandable:before {
    content: '\203A';
    font-weight: bold;
    font-size: 1.2em;
}

.details-firms .main-list .expandable:hover {
    color: #e6e6e6;
}

.details-firms .main-list > ul {
    padding-left: 40px;
    display: none;
}
