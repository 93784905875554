.tableCss {
    width: 100%;
}

.loadingHistory {
    position: absolute;
    width: 100%;
    height: 45%;
    background: url('../../../../../../loading.gif') rgba(24, 118, 64, .75) no-repeat center center;
    background-size: 40px 40px;
    z-index: 1000;
}
