div.floated {
    position: fixed;
    width: 360px;
    top: 0;
    left: 0;
    bottom: 0;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
  }