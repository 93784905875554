.toggle-container {
    display: flex;
    align-items: center;
}

.toggle-container span {
    margin-right: 8px;
    font-size: 17px;
    font-weight: 600;
}

.toggle-button {
    width: 50px;
    height: 24px;
    background-color: #ccc;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.active {
        background-color: #69ac6c;
    }

    .slider {
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2px;
        transition: left 0.3s ease, background-color 0.3s ease;

        &.active {
            left: 28px;
            background-color: #025a1d;
        }
    }
}