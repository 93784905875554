div.company-card {
	position: relative;
	border: 1px solid #edf1f0;
	border-radius: 2px;
	box-shadow: 0 1px 3px 0px #e1e1e1;
}

a.company-graphic {
	width: 100%;
	height: 100%;
	position: relative;
	display: inline-block;
	background-image: url('company.svg'); 
	background-position: center;
	background-size: auto 80%;
	background-repeat: no-repeat;
}

div.radiata{
	background: url('radiata.png');
	margin-bottom: 1%;
	position: relative;
	background-size: auto 150%;
	background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
	a.company-graphic {
		background-size: 50% auto;
	}
}

.card-content .cardHeader{
	display: inline-flex;
}

.card-content h4{
	margin-right: 10px;
	margin-bottom: 0px;
	margin-top: 7px;
	color: red;
	font-size: 18px;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	font-style: bold;
}



div.list-content-body {
	display: table;
	background: #ECF1F3;
	margin-bottom: 15px;
	margin-top: 15px;
	float: none;
	height: 100%;
	padding-bottom: 50px;
  }
  
  div.list-content-body-row {
	display: table-row;
	width: 100%;
	height: 100%;
  }
  
  @media(max-width: 768px) {
	div.list-content-body {
	  padding-left: 0;
	  padding-right: 0;
	}
  }
  
  div.card-graphic {
	position: relative;
	width: 100%;
	height: 60px;
	background-color: #456861;
	background: -webkit-linear-gradient(top, #456861, #3f9264);
	/* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(bottom, #456861, #3f9264);
	/* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(bottom, #456861, #3f9264);
	/* For Firefox 3.6 to 15 */
	background: linear-gradient(to bottom, #456861, #3f9264);
	/* Standard syntax */
  }
  
  div.card-content {
	position: relative;
	height: auto;
	padding: 15px;
	width: 100%;
  }
  
  div.card-content-wrapper {
	overflow: hidden;
  }
  
  div.card-controls {
	position: relative;
	width: 100%;
  }
  
  div.card-controls-center-2 {
	position: relative;
	width: 100%;
	padding: 15px;
  }
  
  div.card-controls-center-2 a:hover {
	text-decoration: none;
  }
  
  div.card-controls-center-3 {
	position: relative;
	width: 100%;
	padding: 45px 15px;
  }
  
  div.card-controls-center-3 a:hover {
	text-decoration: none;
  }
  
  @media screen and (min-width: 768px) {
	div#list {
	  height: calc(100% - 51px);
	}
  
	div#list.closed {
	  position: relative;
	  padding-left: 60px;
	  z-index: 1;
	}
  
	div.card-graphic {
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 75px;
	  height: 100%;
	  z-index: 3;
	}
  
	div.card-content {
	  position: relative;
	  height: 100%;
	  width: 100%;
	  padding-left: 100px;
	  padding-right: 240px;
	  z-index: 2;
	}
  
	div.card-controls {
	  position: absolute;
	  right: 0;
	  top: 0;
	  width: 240px;
	  height: 100%;
	  z-index: 3;
	}
  
	div.card-controls-center-2 {
	  position: absolute;
	  width: 100%;
	  padding: 0 15px;
	  top: calc(50% - 40px);
	  left: 0;
	}
  }
  
  /* Card design stuffs. */
  
  div.spacer {
	height: 15px;
  }
  
  div.spacer-pading-left{
	margin: 1px 0;
	padding-left: 5px;
	position: relative;
  }
  
  div.card-row {
	padding: 8px 0 0 0;
  }
  
  div.card-row-hidden {
	margin-left: -30px;
  }
  
  h3.card-title {
	height: 100%;
	font-size: 1.8em;
	margin: 6px 0 0 0;
	border: 0;
	padding: 0;
	font-weight: 600;
	color: #1A3938;
  }
  
  div.information-component {
	margin: 2px 0;
	padding-left: 30px;
	position: relative;
  }
  
  span.category {
	color: #8B898B;
  }
  
  span.value {
	color: #222222;
  }
  
  
  button.btn-card-nav {
	text-decoration: none;
	color: #8AA29F;
	font-weight: bold;
	padding: 6px 10px 0 0;
	margin-top: 5px;
  }
  
  button.btn-card-nav:hover {
	color: rgba(24, 118, 64, 1);
	text-decoration: none;
  }
  
  button.btn-card-nav:focus {
	text-decoration: none;
	color: #8AA29F !important;
  }
  
  button.btn-monitor {
	color: white;
	background: #F79157;
  }
  
  button.btn-details {
	color: white;
	background: #0F7A3F;
  }
  
  .search-marketing .node-results,
  .search-marketing .county-results,
  .search-marketing .industry-results {
	display: none !important;
  }
  
  @media all and (min-width:1024px) {
	.sidebar-list {
	  width: 16.66%;
	  max-width: 16.66vw;
	  min-width: 260px;
	}
  
	.sidebar-list.closed {
	  min-width: 40px;
	}
  }
  
  @media all and (max-width:1200px) {
	div.card-row-hidden {
	  margin-left: 0;
	}
  }
  
  span.card-separator {
	  position: relative;
	  padding-right: 10px;
	  color: #dde1e0;
  }
  
  div.card-space {
	  padding-right: 10px;
  }