
/* .status-row-sub { width: 25% !important; } */

div.status-row {
	height: 40px;
	margin: 0 -8px;
}

div.status-row-sub {
	height: 40px;
    float: left;
    width: 100%;
	padding: 0 8px;
}

@media screen and (min-width: 768px) {
	div.status-row-sub{
		width: 50%;
	}
}

@media screen and (max-width: 768px) {
	div.leng1, div.leng2, div.leng3 {
		width: 100% !important;
	}
}

@media screen and (min-width: 1200px) {
	div.status-row-sub {
		width: 33%;
	}
}

div.leng1 {
	width: 99%;
}

div.leng2 {
	width: 49.5%;
}

div.leng3 {
	width: 33%;
}

div.licitatii {
	visibility: hidden;
}

div.status-progress-green {
	height: 30%;
	width: 100%;
	background: #8AA29E;
}

div.status-progress-orange {
	height: 30%;
	width: 100%;
	background: rgb(250, 203, 172);
}

div.status-progress-bar {
	height: 100%;
	background: none;
}

@keyframes statusProgress {
	from { width: 0; }
	to { width: 100%; }
}

div.status-progress-animation {
	height: 100%;
	width: 100%;
	animation-name: statusProgress;
	animation-duration: 1s;
}

div.status-progress-animation-green {
	background: #0E7B3E;
}

div.status-progress-animation-orange {
	background: #F79256;
}

span.package-category {
	color: gray;
}

span.package-name {
	max-width: 40px;
	overflow: visible;
}
