.languageSelector {
    margin-right: 10px;
}

@media screen and (max-width: 768px) {
    .languageSelector{
        margin-left: -10px;
    }
}

.languageSelector .szh-menu {
    background-color: #305C53 !important;
    width: 85px !important;
}

.languageSelector .szh-menu-button {
    background-color: #305C53;
    width: 40px;
    height: 40px;
    margin-top: -4px !important;
    padding-left: 3px;
    border: 2px solid #294C35;
    border-radius: 5px !important;
}

.flagImg {
    width: 30px;
    height: 30px;
}
