.dosareFilters {
    margin-left: 10px;
    display: flex;
    gap: 30px;
    align-items: center;
}

.dosareContentModal {
    width: 50vw;
    height: 2000px;
    overflow-y: auto;
}

.dosarContentBody table{
    margin: 5px;
}

.dosarContentBody p {
    margin-left: 10px;
    color: red;
    font-weight: bold;
}