span.show-menu-button {
  font-size: 2em;
  padding-top: 1px;
  background: none;
  margin-right: 15px;
}

@media (max-width:768px) {
  .show-menu-parent {
    float: right!important;
  }
}
